import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Button, ButtonToolbar } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          Buttons are the bread and butter of actions that users perform in our account system. Buttons express what
          action will occur when the user clicks or touches it. Buttons are used to initialize an action, either in the
          background or foreground of an experience.
        </p>
        <p>
          Buttons are used primarily on action items. Some examples include Add, Save, Delete, and Sign up. Each page
          can have one or two primary buttons. Any remaining calls-to-action should be represented as secondary buttons.
        </p>
        <p>
          Do not use buttons as navigational elements. Instead, use links when the desired action is to take the user to
          a new page.
        </p>
      </>
    );

    var codeString = `
    // Default Buttons
    <ButtonToolbar>
      <Button variant="primary">Primary</Button>
      <Button variant="secondary">Secondary</Button>
      <Button variant="success">Success</Button>
      <Button variant="warning">Warning</Button>
      <Button variant="danger">Danger</Button>
      <Button variant="info">Info</Button>
    </ButtonToolbar>

    // Outline Buttons
    <ButtonToolbar>
      <Button variant="outline-primary">Primary</Button>
      <Button variant="outline-secondary">Secondary</Button>
      <Button variant="outline-success">Success</Button>
      <Button variant="outline-danger">Danger</Button>
      <Button variant="outline-info">Info</Button>
    </ButtonToolbar>

    // Ghost Buttons
    <ButtonToolbar>
      <Button variant="primary-ghost">Primary Link</Button>
      <Button variant="secondary-ghost">Secondary Link</Button>
      <Button variant="danger-ghost">Danger Link</Button>
    </ButtonToolbar>

    // Disabled Buttons
    <ButtonToolbar>
      <Button variant="primary" disabled>Primary</Button>
      <Button variant="secondary" disabled>Secondary</Button>
      <Button variant="success" disabled>Success</Button>
      <Button variant="warning" disabled>Warning</Button>
      <Button variant="danger" disabled>Danger</Button>
      <Button variant="info" disabled>Info</Button>
    </ButtonToolbar>

    // Buttons with Icons
    <ButtonToolbar>
      <Button variant="primary">
        <i className="fa fa-check" aria-hidden="true"></i>Primary
      </Button>
      <Button variant="secondary">
        <i className="fa fa-check" aria-hidden="true"></i>Secondary
      </Button>
      <Button variant="outline-primary">
        <i className="fa fa-check" aria-hidden="true"></i>Primary
      </Button>
      <Button variant="outline-secondary">
        <i className="fa fa-check" aria-hidden="true"></i>Secondary
      </Button>
      <Button variant="primary-ghost">
        <i className="fa fa-check" aria-hidden="true"></i>Primary
      </Button>
      <Button variant="secondary-ghost">
        <i className="fa fa-check" aria-hidden="true"></i>Primary
      </Button>
      <Button variant="danger-ghost">
        <i className="fa fa-check" aria-hidden="true"></i>Danger
      </Button>
    </ButtonToolbar>

    // Button Toolbar (for more than 2 consecutive buttons)
    <ButtonToolbar>
      <Button variant="outline-primary">
        <i className="fa fa-circle" aria-hidden="true"></i>Button 1
      </Button>
      <Button variant="outline-primary">
        <i className="fa fa-square" aria-hidden="true"></i>Button 2
      </Button>
      <Button variant="outline-primary">
        <i className="fa fa-circle" aria-hidden="true"></i>Button 3
      </Button>
      <Button variant="outline-primary">
        <i className="fa fa-square" aria-hidden="true"></i>Button 4
      </Button>
    </ButtonToolbar>
    
    <ButtonToolbar>
      <Button variant="outline-secondary">
        <i className="fa fa-circle" aria-hidden="true"></i>Button 1
      </Button>
      <Button variant="outline-secondary">
        <i className="fa fa-square" aria-hidden="true"></i>Button 2
      </Button>
      <Button variant="outline-secondary">
        <i className="fa fa-circle" aria-hidden="true"></i>Button 3
      </Button>
      <Button variant="outline-secondary">
        <i className="fa fa-square" aria-hidden="true"></i>Button 4
      </Button>
    </ButtonToolbar>
    `;

    var code = (
      <>
        <br />
        <h4>Default</h4>
        <ButtonToolbar>
          <Button variant="primary">Primary</Button>
          <Button variant="secondary">Secondary</Button>
          <Button variant="success">Success</Button>
          <Button variant="warning">Warning</Button>
          <Button variant="danger">Danger</Button>
          <Button variant="info">Info</Button>
        </ButtonToolbar>

        <br />
        <h4>Outline</h4>
        <ButtonToolbar>
          <Button variant="outline-primary">Primary</Button>
          <Button variant="outline-secondary">Secondary</Button>
          <Button variant="outline-success">Success</Button>
          <Button variant="outline-danger">Danger</Button>
          <Button variant="outline-info">Info</Button>
        </ButtonToolbar>

        <br />
        <h4>Ghost</h4>
        <ButtonToolbar>
          <Button variant="primary-ghost">Primary Link</Button>
          <Button variant="secondary-ghost">Secondary Link</Button>
          <Button variant="danger-ghost">Danger Link</Button>
        </ButtonToolbar>

        <br />
        <h4>Disabled</h4>
        <ButtonToolbar>
          <Button variant="primary" disabled>Primary</Button>
          <Button variant="secondary" disabled>Secondary</Button>
          <Button variant="success" disabled>Success</Button>
          <Button variant="warning" disabled>Warning</Button>
          <Button variant="danger" disabled>Danger</Button>
          <Button variant="info" disabled>Info</Button>
        </ButtonToolbar>

        <br />

        <h4>Buttons with Icons</h4>
        <ButtonToolbar>
          <Button variant="primary">
            <i className="fa fa-check" aria-hidden="true"></i>Primary
          </Button>
          <Button variant="secondary">
            <i className="fa fa-check" aria-hidden="true"></i>Secondary
          </Button>
          <Button variant="outline-primary">
            <i className="fa fa-check" aria-hidden="true"></i>Primary
          </Button>
          <Button variant="outline-secondary">
            <i className="fa fa-check" aria-hidden="true"></i>Secondary
          </Button>
          <Button variant="primary-ghost">
            <i className="fa fa-check" aria-hidden="true"></i>Primary
          </Button>
          <Button variant="secondary-ghost">
            <i className="fa fa-check" aria-hidden="true"></i>Primary
          </Button>
          <Button variant="danger-ghost">
            <i className="fa fa-check" aria-hidden="true"></i>Danger
          </Button>
        </ButtonToolbar>

        <br />

        <h4>Button Toolbar</h4>
        <p>When using 3 or more buttons in a row, use the primary or secondary ghost buttons.</p>
        <ButtonToolbar>
          <Button variant="outline-primary">
            <i className="fa fa-circle" aria-hidden="true"></i>Button 1
          </Button>
          <Button variant="outline-primary">
            <i className="fa fa-square" aria-hidden="true"></i>Button 2
          </Button>
          <Button variant="outline-primary">
            <i className="fa fa-circle" aria-hidden="true"></i>Button 3
          </Button>
          <Button variant="outline-primary">
            <i className="fa fa-square" aria-hidden="true"></i>Button 4
          </Button>
        </ButtonToolbar>
        <br />
        <ButtonToolbar>
          <Button variant="outline-secondary">
            <i className="fa fa-circle" aria-hidden="true"></i>Button 1
          </Button>
          <Button variant="outline-secondary">
            <i className="fa fa-square" aria-hidden="true"></i>Button 2
          </Button>
          <Button variant="outline-secondary">
            <i className="fa fa-circle" aria-hidden="true"></i>Button 3
          </Button>
          <Button variant="outline-secondary">
            <i className="fa fa-square" aria-hidden="true"></i>Button 4
          </Button>
        </ButtonToolbar>

        <br />
      </>
    );

    var codes = [
      {
        code: code,
        codeLanguage: "html",
        codeString: codeString
      }
    ]

    var accessibilitySection = (
      <>
        <h5>Buttons, Links, & Clickable Elements Should Have Accessible Labels That Match</h5>
        <p>Accessible labels for links, buttons, and clickable elements should include any visible text.</p>
        <h5>Why</h5>

        <p>
          There shouldn't be a difference in how users interact with buttons and clickable elements. Buttons with a
          visual component (like "Get Started" or "Submit") should have a matching programmatic name.
        </p>

        <p>
          This ensures that all users will have the same experience. A screen reader user would have the text "Get
          Started" read out to them; a sighted user would read "Get Started"; and a speech-to-text user would speak "Get
          Started."
        </p>

        <p>
          Contradictory labels (i.e., a button with visual text "Get Started" and programmatic label "Sign Up") are
          extremely confusing for users, and are particularly challenging for any users with cognitive disabilities.
        </p>

        <h5>Best Practices</h5>
        <ul>
          <li>
            Conventionally, the label for user interface components is the adjacent text string. The typical positioning
            of the label is inside buttons and tabs or immediately below icons serving as buttons.
          </li>
          <li>
            It's not necessary to include symbols (e.g., the colon in "First Name:") in labels. "First Name" would be
            sufficient.
          </li>
          <li>`aria-label` and `aria-labelledby` override native label semantics</li>
          <li>
            If the visible button text is a symbol (for example, an icon of a list), the accessible label should
            indicate the function of the icon ("bulleted list")
          </li>
        </ul>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Buttons"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
